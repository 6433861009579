<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row v-if="dependents && dependents.length > 0" class="mt-2" align="center">
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <p class="title">
              {{ dependents.length > 1 ? 'Dados dos Dependentes' : 'Dados do Dependente' }}
            </p>
          </v-col>
        </v-row>
        <v-row v-show="insuredType !== 'dependent' && viewCheckbox" v-if="dependents && dependents.length > 0" class="ml-1">
          <v-checkbox
            v-model="showInactive"
            color="primary"
            label="Exibir dependentes Inativos"
            :disabled="disabledShowInactive"
          />
        </v-row>
        <v-row v-if="dependents && dependents.length > 0">
          <v-row v-for="(item, index) in dependents" :key="index">
            <v-col v-show="showInactive || item.isActive" cols="12" :class="dependents.length > 1 ? 'mt-4' : ''">
              <v-card class="px-5 mx-3" elevation="1">
                <v-row class="mt-2">
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">CPF</label>
                    <v-text-field
                      class="mt-2"
                      :value="formatter.formatCpf(item.physicalPersonCPF)"
                      :placeholder="placeholderNoData"
                      v-mask="'###.###.###-##'"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Nome</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.physicalPersonName ? formatter.formatToTitleCase(item.physicalPersonName) : null"
                      :placeholder="placeholderNoData"
                      required
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Data de nascimento</label>
                    <v-text-field
                      class="mt-2"
                      :value="formatter.formatDate(item.physicalPersonBirthDate)"
                      :placeholder="placeholderNoData"
                      color="textPrimary"
                      outlined
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">UF de Nascimento</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.stateBirth"
                      outlined
                      color="textPrimary"
                      :placeholder="placeholderNoData"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Gênero</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.genderDescription"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Nome da Mãe</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.mothersName ? formatter.formatToTitleCase(item.mothersName) : null"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Grau de Parentesco</label>
                    <v-text-field
                      class="mt-2"
                      v-model="item.kinship"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">CNS</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.cns"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">DNV</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.dnv"
                      :placeholder="placeholderNoData"
                      v-mask="'##-########-#'"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Peso(kg)</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.weight"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Altura(cm)</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.height"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Data do evento</label>
                    <v-text-field
                      class="mt-2"
                      :value="formatter.formatDate(item.eventDate)"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Data Início de Vigência</label>
                    <v-text-field
                      class="mt-2"
                      :value="formatter.formatDate(item.startDate)"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Data Fim de vigência</label>
                    <v-text-field
                      class="mt-2"
                      :value="formatter.formatDate(item.endDate)"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Estado Civil</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.maritalStatusDescription"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col v-show="insuredType !== 'dependent'" cols="12" xs="12" sm="12" md="3">
                    <label class="label">Motivo de Exclusão</label>
                    <v-text-field
                      class="mt-2"
                      v-model="item.exclusionReason"
                      placeholder="Não informado"
                      outlined
                      color="textPrimary"
                      disabled
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Carteirinha do Dependente</label>
                    <v-text-field
                      class="mt-2"
                      maxlength="50"
                      v-model="item.memberCard"
                      placeholder="Não informado"
                      outlined
                      color="textPrimary"
                      disabled
                      @input="observerHolderInformationData()"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Carteirinha Congênere</label>
                    <v-text-field
                      class="mt-2"
                      maxlength="50"
                      v-model="item.congenericCard"
                      placeholder="Não informado"
                      outlined
                      color="textPrimary"
                      disabled
                      @input="observerHolderInformationData()"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Código Familiar</label>
                    <v-text-field
                      outlined
                      class="mt-2"
                      v-model="item.memberFamilyCode"
                      color="textPrimary"
                      placeholder="Informe  código familiar"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Sequencial</label>
                    <v-text-field
                      outlined
                      class="mt-2"
                      v-model="item.memberFamilyCodeSeq"
                      color="textPrimary"
                      placeholder="Informe código sequencial"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3">
                    <label class="label">Carência</label>
                    <v-text-field
                      class="mt-2"
                      :value="item.hasGracePeriod !== null ? item.hasGracePeriod === true ? 'Sim' : 'Não' : null"
                      :placeholder="placeholderNoData"
                      outlined
                      color="textPrimary"
                      disabled
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-else class="mt-4 text-center" align="center">
          <v-col cols="12">
            <h3 class="text--disabled">
              Não há dependentes
            </h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default {
  data: () => ({
    placeholderNoData: 'Não informado',
    dependents: [],
    showInactive: false,
    disabledShowInactive: false,
  }),

  props: {
    propsDependents: {
      type: Array,
      required: true,
    },
    isActiveInsured: {
      type: Boolean,
      required: false,
    },
    viewCheckbox: {
      type: Boolean,
      required: true,
    },
    insuredType: {
      type: String,
      required: true,
    },
  },

  watch: {
    propsDependents: {
      handler(val) {
        if (val) {
          this.dependents = val;
        }
      },
    },
    isActiveInsured: {
      immediate: true,
      handler(val) {
        if (val) {
          this.showInactive = false;
        } else {
          this.showInactive = true;
          this.disabledShowInactive = true;
        }
      },
    },
  },

  created() {
    this.formatter = new Formatters();
  },
};
</script>
