<template>
  <v-container fluid>
    <v-row v-if="showHeaderPage" class="mt-5">
      <v-col cols="12" md="3" align="start">
        <p class="title">Dados Bancários</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="bank.code"
          :items="banks"
          item-text="description"
          item-value="code"
          outlined
          label="Cód. do Banco"
          placeholder="Cod. do Banco"
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          clearable
          :disabled="isDetail"
          @input="observerBankData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="bank.agency"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="bank.agency ? rule.verifyNegative(bank.agency, 'fieldCheckedBankAgency') : ''"
          @focus="verifyMessageBankAgency = rule.isFocusVerifyNegative(true, bank.agency)"
          @change="verifyMessageBankAgency = rule.isFocusVerifyNegative(false, bank.agency)"
          id="fieldCheckedBankAgency"
          v-mask="'#########'"
          :error-messages="verifyMessageBankAgency ? '*Preencha corretamente' : ''"
          label="Agência"
          placeholder="Informe apenas números"
          color="textPrimary"
          outlined
          :rules="bank.agency ? [rule.validateNegativeDecimal(bank.agency, 'int')] : []"
          :disabled="isDetail"
          @input="observerBankData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="bank.agencyDigit"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="bank.agencyDigit ? rule.verifyNegative(bank.agencyDigit, 'fieldCheckedBankAgencyDigit') : ''"
          @focus="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(true, bank.agencyDigit)"
          @change="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(false, bank.agencyDigit)"
          id="fieldCheckedBankAgencyDigit"
          :error-messages="verifyMessageBankAgencyDigit ? '*Preencha corretamente' : ''"
          v-mask="'#'"
          label="DV"
          color="textPrimary"
          outlined
          :disabled="isDetail"
          @input="observerBankData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="bank.account"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="bank.account ? rule.verifyNegative(bank.account, 'fieldCheckedBankAccount') : ''"
          @focus="verifyMessageBankAccount = rule.isFocusVerifyNegative(true, bank.account)"
          @change="verifyMessageBankAccount = rule.isFocusVerifyNegative(false, bank.account)"
          id="fieldCheckedBankAccount"
          v-mask="'#########'"
          :error-messages="verifyMessageBankAccount ? '*Preencha corretamente' : ''"
          label="Nº da Conta"
          placeholder="Informe apenas números"
          color="textPrimary"
          outlined
          :rules="bank.account ? [rule.validateNegativeDecimal(bank.account, 'int')] : []"
          validate-on-blur
          :disabled="isDetail"
          @input="observerBankData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="bank.accountDigit"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="bank.accountDigit ? rule.verifyNegative(bank.accountDigit, 'fieldCheckedBankAccountDigit') : ''"
          @focus="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(true, bank.accountDigit)"
          @change="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(false, bank.accountDigit)"
          id="fieldCheckedBankAccountDigit"
          v-mask="'#'"
          label="DV"
          :error-messages="verifyMessageBankAccountDigit ? '*Preencha corretamente' : ''"
          color="textPrimary"
          outlined
          :disabled="isDetail"
          @input="observerBankData()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Rules from '@/shared/validators/formRules';
import BankService from '@/services-http/sdi/BankService';

export default {
  name: 'BankInformations',
  data: () => ({
    bank: {
      code: null,
      agency: null,
      agencyDigit: null,
      account: null,
      accountDigit: null,
    },
    banks: [],
    verifyMessageBankAgency: false,
    verifyMessageBankAgencyDigit: false,
    verifyMessageBankAccount: false,
    verifyMessageBankAccountDigit: false,
  }),

  props: {
    bankData: {
      type: Object,
      required: false,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    isFreeMovement: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    bankData: {
      immediate: true,
      handler(val) {
        if (val) {
          this.bank = val;
        }
      },
      deep: true,
    },
    bank: {
      handler(val) {
        if (val.code === '') {
          this.bank.code = null;
        }
        if (val.agency === '') {
          this.bank.agency = null;
        }
        if (val.agencyDigit === '') {
          this.bank.agencyDigit = null;
        }
        if (val.account === '') {
          this.bank.account = null;
        }
        if (val.accountDigit === '') {
          this.bank.accountDigit = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    this.loadBanks();
    this.loadHolderBankData();
  },

  methods: {
    loadBanks() {
      this.bankService.FindAll().then((response) => {
        if (response && response.data && response.data.content) {
          let banks = response.data.content;
          banks = banks.map((bank) => ({
            id: bank.id,
            description: bank && bank.code ? `${bank.code} - ${bank.name}` : bank.name,
            code: bank.code,
          }));
          this.banks = banks;
        }
      });
    },
    loadHolderBankData() {
      if (sessionStorage.getItem('movementRecord')) {
        const movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.bank.code = movementRecord.bank ? Number(movementRecord.bank) : null;
        this.bank.agency = movementRecord.branchBank;
        this.bank.agencyDigit = movementRecord.branchBankCheckDigit;
        this.bank.account = movementRecord.bankAccount;
        this.bank.accountDigit = movementRecord.bankAccountCheckDigit;
      }
    },
    observerBankData() {
      this.$emit('observerBankData', this.bank);
    },
  },
  async created() {
    this.rule = new Rules();
    this.bankService = new BankService();
  },
};
</script>
